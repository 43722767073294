import { ApolloClient, DocumentNode } from '@apollo/client/core';
import { GraphQLService } from './GraphQLService';
import { LOCAL_FIELDS } from '../../constants';
import { ClientFetchResult, ClientQueryResult } from './types';

class ApolloClientGraphQLService implements GraphQLService {
  private _apolloClient: ApolloClient<unknown>;

  constructor(apolloClient: ApolloClient<unknown>) {
    this._apolloClient = apolloClient;
  }

  async query<TData, TVariables>(
    query: DocumentNode,
    variables?: TVariables
  ): Promise<ClientQueryResult<TData>> {
    const result = await this._apolloClient.query<TData, TVariables>({
      query,
      variables
    });

    const response = JSON.parse(
      JSON.stringify(result)
    ) as ClientQueryResult<TData>;

    delete response?.data?.[LOCAL_FIELDS.devices as keyof TData];

    return response;
  }

  async mutate<TData, TVariables>(
    mutation: DocumentNode,
    variables?: TVariables
  ): Promise<ClientFetchResult<TData>> {
    const response = await this._apolloClient.mutate<TData, TVariables>({
      mutation,
      variables
    });

    return response as ClientFetchResult<TData>;
  }
}

export default ApolloClientGraphQLService;

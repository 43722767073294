import bindAllMethods from '../../../utils/bindAllMethods';
import { getServices } from '../../../infra/commonInitializer';
import { GraphQLInterfaceV1Type } from './types';
import IGraphQLService from '../../../services/graphQLService/IGraphQLService';
import { DocumentNode } from '@clientos/graphql-client';
import { ClientQueryResult } from '@clientos/graphql-client/dist/services/GraphQLService/types';

class GraphQLInterfaceV1 {
  private _graphQLService: IGraphQLService;

  constructor() {
    const { graphQLService } = getServices();
    this._graphQLService = graphQLService;
    bindAllMethods(this);
  }

  public getInterface(): GraphQLInterfaceV1Type {
    const { gql, getClient, getReactTools } = this._graphQLService;
    const { createGraphQLProvider, useQuery, useMutation } = getReactTools();

    return {
      gql,
      query: this.query,
      getClient,
      reactTools: { createGraphQLProvider, useQuery, useMutation }
    };
  }

  private async query<TData, TVariables>(
    query: DocumentNode,
    variables?: TVariables
  ): Promise<ClientQueryResult<TData>> {
    const { getClient } = this._graphQLService;
    const client = getClient();

    return client.query(query, variables);
  }
}

export default GraphQLInterfaceV1;
